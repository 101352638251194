
import { defineComponent, onMounted, ref } from "vue";
import { getStreamsDetail } from "./streams";
import { ErrorMessage, Field, Form } from "vee-validate";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import * as Yup from "yup";
import ApiService from "@/core/services/ApiService";
import { useRouter, useRoute } from "vue-router";
import moment from "moment";
import { updateStreamsSosmed } from "./streams";
import { getScraperSosmed } from "./streams";
import Swal from "sweetalert2/dist/sweetalert2.js";

interface MediaSosialForm {
  title: string;
  source: string;
  account: string;
  id_origin: string;
  content: string;
  date: string;
  emotion: string;
  engagement_count: number;
  engagement_detail: {
    like: number;
    comment: number;
    view: number;
    favorite: number;
    quote: number;
    reply: number;
    rt: number;
  };
  followers: number;
  url: string;
  sentiment: string;
}

export default defineComponent({
  name: "input-berita-medsos",
  components: {
    ErrorMessage,
    Field,
    Form,
  },
  setup() {
    const submitButton1 = ref<HTMLElement | null>(null);
    const getDataButton1 = ref<HTMLElement | null>(null);
    const loading = ref(false);
    const route = useRoute();
    const berita = ref();
    const id = ref(route.params.id);
    const media_options = ref([]);
    const router = useRouter();
    const valueContent = ref("");
    const keyContent = ref(0);
    const rateAccountMedia = ref(0);
    const lebarAccountMedia = ref(0);
    const isEnableField = ref(true);
    const shortUrl = ref("");
    const twitterUrl = ref("");

    const MediaSosialForm = ref<MediaSosialForm>({
      title: "",
      source: "",
      account: "",
      id_origin: "",
      content: "",
      date: "",
      emotion: "",
      engagement_count: 0,
      engagement_detail: {
        like: 0,
        comment: 0,
        view: 0,
        favorite: 0,
        quote: 0,
        reply: 0,
        rt: 0,
      },
      followers: 0,
      url: "",
      sentiment: "",
    });

    const fetchStreamDetail = async () => {
      const res = await getStreamsDetail(id.value);
      berita.value = res;
      if (res.title) {
        MediaSosialForm.value.title = res.title;
      }
      MediaSosialForm.value.source = res.source;
      MediaSosialForm.value.account = res.account;
      MediaSosialForm.value.id_origin = res.id_origin;
      MediaSosialForm.value.content = res.content;
      MediaSosialForm.value.date = res.date;
      MediaSosialForm.value.emotion = res.emotion;
      MediaSosialForm.value.engagement_count = res.engagement_count;
      if (res.engagement_detail.like) {
        MediaSosialForm.value.engagement_detail.like =
          res.engagement_detail.like;
      }
      if (res.engagement_detail.comment) {
        MediaSosialForm.value.engagement_detail.comment =
          res.engagement_detail.comment;
      }
      if (res.engagement_detail.view) {
        MediaSosialForm.value.engagement_detail.view =
          res.engagement_detail.view;
      }
      if (res.engagement_detail.favorite) {
        MediaSosialForm.value.engagement_detail.favorite =
          res.engagement_detail.favorite;
      }
      if (res.engagement_detail.fav) {
        MediaSosialForm.value.engagement_detail.favorite =
          res.engagement_detail.fav;
      }
      if (res.engagement_detail.quote) {
        MediaSosialForm.value.engagement_detail.quote =
          res.engagement_detail.quote;
      }
      if (res.engagement_detail.reply) {
        MediaSosialForm.value.engagement_detail.reply =
          res.engagement_detail.reply;
      }
      if (res.engagement_detail.rt) {
        MediaSosialForm.value.engagement_detail.rt = res.engagement_detail.rt;
      }
      MediaSosialForm.value.followers = res.followers;
      MediaSosialForm.value.url = res.url;
      if (MediaSosialForm.value.source == "youtube") {
        shortUrl.value = MediaSosialForm.value.url.substring(32, 100);
      } else if (MediaSosialForm.value.source == "twitter") {
        let index = MediaSosialForm.value.url.indexOf("status/");
        twitterUrl.value = MediaSosialForm.value.url.substring(0, index + 7);
        shortUrl.value = MediaSosialForm.value.url.substring(index + 7);
      } else if (MediaSosialForm.value.source == "instagram") {
        shortUrl.value = MediaSosialForm.value.url.substring(24, 100);
      } else {
        shortUrl.value = MediaSosialForm.value.url;
      }
      MediaSosialForm.value.sentiment = res.sentiment;
      valueContent.value = berita?.value.content;
      keyContent.value += 1;
    };

    const MediaSosialFormValidator = Yup.object().shape({
      source: Yup.string().required().label("Sumber Berita"),
      url: Yup.string().required().label("URL Berita"),
      title: Yup.string().when("source", {
        is: (val) => val === "youtube",
        then: Yup.string().required().label("Judul Berita"),
        otherwise: Yup.string().notRequired(),
      }),
      account: Yup.string().required().label("Nama Akun"),
      // date: Yup.string().required().label("Tanggal Berita"),
      favorite: Yup.number().when("source", {
        is: (val) => val === "youtube" || val === "twitter",
        then: Yup.number().min(0).required().label("Jumlah Favorite"),
        otherwise: Yup.number().notRequired(),
      }),
      like: Yup.number().when("source", {
        is: (val) => val === "youtube" || val === "instagram",
        then: Yup.number().min(0).required().label("Jumlah Like"),
        otherwise: Yup.number().notRequired(),
      }),
      comment: Yup.number().when("source", {
        is: (val) => val === "youtube" || val === "instagram",
        then: Yup.number().min(0).required().label("Jumlah Komentar"),
        otherwise: Yup.number().notRequired(),
      }),
      view: Yup.number().when("source", {
        is: (val) => val === "youtube",
        then: Yup.number().min(0).required().label("Jumlah Viewer"),
        otherwise: Yup.number().notRequired(),
      }),
      quote: Yup.number().when("source", {
        is: (val) => val === "twitter",
        then: Yup.number().min(0).required().label("Jumlah Quote"),
        otherwise: Yup.number().notRequired(),
      }),
      reply: Yup.number().when("source", {
        is: (val) => val === "twitter",
        then: Yup.number().min(0).required().label("Jumlah Reply"),
        otherwise: Yup.number().notRequired(),
      }),
      retweet: Yup.number().when("source", {
        is: (val) => val === "twitter",
        then: Yup.number().min(0).required().label("Jumlah Retweet"),
        otherwise: Yup.number().notRequired(),
      }),
      followers: Yup.number().required().label("Jumlah Followers"),
      // content: Yup.string().required().label("Konten Berita"),
    });

    const isNumber = (evt) => {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        (charCode !== 46 || charCode !== 190)
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    };

    const saveChanges1 = async () => {
      if (submitButton1.value) {
        // Activate indicator
        submitButton1.value.setAttribute("data-kt-indicator", "on");
        let instagram_engagement = {
          like: MediaSosialForm.value.engagement_detail.like
            ? MediaSosialForm.value.engagement_detail.like
            : 0,
          comment: MediaSosialForm.value.engagement_detail.comment
            ? MediaSosialForm.value.engagement_detail.comment
            : 0,
        };
        let youtube_engagement = {
          comment: MediaSosialForm.value.engagement_detail.comment
            ? MediaSosialForm.value.engagement_detail.comment
            : 0,
          favorite: MediaSosialForm.value.engagement_detail.favorite
            ? MediaSosialForm.value.engagement_detail.favorite
            : 0,
          like: MediaSosialForm.value.engagement_detail.like
            ? MediaSosialForm.value.engagement_detail.like
            : 0,
          view: MediaSosialForm.value.engagement_detail.view
            ? MediaSosialForm.value.engagement_detail.view
            : 0,
        };
        let twitter_engagement = {
          fav: MediaSosialForm.value.engagement_detail.favorite
            ? MediaSosialForm.value.engagement_detail.favorite
            : 0,
          quote: MediaSosialForm.value.engagement_detail.quote
            ? MediaSosialForm.value.engagement_detail.quote
            : 0,
          reply: MediaSosialForm.value.engagement_detail.reply
            ? MediaSosialForm.value.engagement_detail.reply
            : 0,
          rt: MediaSosialForm.value.engagement_detail.rt
            ? MediaSosialForm.value.engagement_detail.rt
            : 0,
        };
        let tiktok_engagement = {
          comment: MediaSosialForm.value.engagement_detail.comment
            ? MediaSosialForm.value.engagement_detail.comment
            : 0,
          like: MediaSosialForm.value.engagement_detail.like
            ? MediaSosialForm.value.engagement_detail.like
            : 0,
        };
        let params = {
          source: MediaSosialForm.value.source,
          account: MediaSosialForm.value.account,
          id_origin: MediaSosialForm.value.id_origin,
          content: MediaSosialForm.value.content,
          date: moment(MediaSosialForm.value.date).format(
            "YYYY-MM-DD HH:mm:ss"
          ),
          emotion: MediaSosialForm.value.emotion,
          engagement_count: MediaSosialForm.value.engagement_count,
          engagement_detail: {},
          followers: MediaSosialForm.value.followers,
          url: MediaSosialForm.value.url,
          sentiment: MediaSosialForm.value.sentiment,
        };
        if (MediaSosialForm.value.source == "twitter") {
          params.engagement_detail = twitter_engagement;
        }
        if (MediaSosialForm.value.source == "youtube") {
          params.engagement_detail = youtube_engagement;
        }
        if (
          MediaSosialForm.value.source == "instagram" ||
          MediaSosialForm.value.source == "facebook"
        ) {
          params.engagement_detail = instagram_engagement;
        }
        if (MediaSosialForm.value.source == "tiktok") {
          params.engagement_detail = tiktok_engagement;
        }
        await updateStreamsSosmed(params, id.value)
          .then((response) => {
            if (response.status == 200) {
              Swal.fire({
                position: "top-end",
                icon: "success",
                toast: true,
                title: "Berhasil! Perubahan berita berhasil disimpan",
                showConfirmButton: false,
                timer: 3000,
              });
            } else {
              Swal.fire({
                position: "top-end",
                icon: "error",
                toast: true,
                title: "Gagal! Perubahan berita berhasil disimpan",
                showConfirmButton: false,
                timer: 3000,
              });
            }
          })
          .catch((error) => {
            Swal.fire({
              position: "top-end",
              icon: "error",
              toast: true,
              title: "Gagal! Perubahan berita berhasil disimpan",
              showConfirmButton: false,
              timer: 3000,
            });
          })
          .finally(() => {
            // Deactivate indicator
            submitButton1.value?.removeAttribute("data-kt-indicator");
          });
      }
    };

    const remoteMethod = (query) => {
      if (query.length > 2) {
        loading.value = true;
        let url = "/media?media_like=" + query + "&limit=100&page=1";
        if (MediaSosialForm.value["source"] != "") {
          url = url + "&source=" + MediaSosialForm.value["source"];
        }
        ApiService.get(url)
          .then((response) => {
            media_options.value = response.data.data;
            return media_options.value;
          })
          .catch((error) => {
            console.log(error);
            loading.value = false;
          })
          .finally(() => {
            loading.value = false;
          });
        return [];
      } else {
        media_options.value = [];
      }
    };

    const convertText = (valueContent) => {
      if (MediaSosialForm.value["content"] != null) {
        let text = MediaSosialForm.value["content"].split("\n\n");
        let newText = "";

        for (let i = 0; i < text.length; i++) {
          let perTextArray = text[i].split("\n");
          for (let j = 0; j < perTextArray.length; j++) {
            if (perTextArray[j].charAt(perTextArray[j].length - 1) == "-") {
              newText += perTextArray[j].replace(/-/g, "");
            } else {
              newText += perTextArray[j] + " ";
            }
          }
          newText += "\n";
        }
        MediaSosialForm.value.content = newText;
        valueContent = newText;
      }
    };

    const getContentUrl = async () => {
      getDataButton1.value?.setAttribute("data-kt-indicator", "on");
      getDataButton1.value?.setAttribute("disabled", "disabled");
      let url = "";
      let params = {};
      if (MediaSosialForm.value.source == "youtube") {
        url = "/scraper/youtube/post";
        params = {
          ids: shortUrl.value.replaceAll(" ", ""),
        };
      }
      if (MediaSosialForm.value.source == "instagram") {
        url = "/scraper/instagram/post";
        params = {
          shortcode: shortUrl.value.trim(),
        };
      }
      if (MediaSosialForm.value.source == "twitter") {
        url = "/scraper/twitter/post";
        params = {
          ids: shortUrl.value.replaceAll(" ", ""),
        };
      }
      if (MediaSosialForm.value.source == "tiktok") {
        url = "/scraper/tiktok/post";
        params = {
          url: shortUrl.value.replaceAll(" ", ""),
        };
      }
      let response = await getScraperSosmed(url, params);
      if (response["error"] || response["result"].error) {
        Swal.fire({
          text: "Gagal Mendapatkan Informasi Berita ! Silahkan Masukkan Ulang ID Postingan atau Masukkan ID Postingan Lain...",
          icon: "error",
          confirmButtonText: "Ok",
          buttonsStyling: false,
          customClass: {
            confirmButton: "btn btn-light-primary",
          },
        });
        getDataButton1.value?.removeAttribute("data-kt-indicator");
        getDataButton1.value?.removeAttribute("disabled");
      } else {
        let res;
        if (
          MediaSosialForm.value.source == "instagram" ||
          MediaSosialForm.value.source == "tiktok"
        ) {
          res = response["result"];
        } else {
          res = response["result"][0];
        }
        if (res.title) {
          MediaSosialForm.value.title = res.title;
        }
        if (MediaSosialForm.value.source != "tiktok") {
          MediaSosialForm.value.account = res.account;
        } else {
          console.log("masuk sini");
          MediaSosialForm.value.account = res.account_name;
        }
        MediaSosialForm.value.id_origin = res.id_origin;
        MediaSosialForm.value.content = res.content;
        MediaSosialForm.value.date = res.date;
        MediaSosialForm.value.engagement_count = res.engagement_count;
        if (res.engagement_detail.like) {
          MediaSosialForm.value.engagement_detail.like =
            res.engagement_detail.like;
        }
        if (res.engagement_detail.comment) {
          MediaSosialForm.value.engagement_detail.comment =
            res.engagement_detail.comment;
        }
        if (res.engagement_detail.comment_count) {
          MediaSosialForm.value.engagement_detail.comment =
            res.engagement_detail.comment_count;
        }
        if (res.engagement_detail.view) {
          MediaSosialForm.value.engagement_detail.view =
            res.engagement_detail.view;
        }
        if (res.engagement_detail.favorite) {
          MediaSosialForm.value.engagement_detail.favorite =
            res.engagement_detail.favorite;
        }
        if (res.engagement_detail.fav) {
          MediaSosialForm.value.engagement_detail.favorite =
            res.engagement_detail.fav;
        }
        if (res.engagement_detail.quote) {
          MediaSosialForm.value.engagement_detail.quote =
            res.engagement_detail.quote;
        }
        if (res.engagement_detail.reply) {
          MediaSosialForm.value.engagement_detail.reply =
            res.engagement_detail.reply;
        }
        if (res.engagement_detail.rt) {
          MediaSosialForm.value.engagement_detail.rt = res.engagement_detail.rt;
        }
        MediaSosialForm.value.followers = res.followers;
        isEnableField.value = false;
        getDataButton1.value?.removeAttribute("data-kt-indicator");
        getDataButton1.value?.removeAttribute("disabled");
      }
    };

    onMounted(() => {
      setCurrentPageBreadcrumbs("Input Berita", ["Berita"]);
      fetchStreamDetail();
    });
    return {
      submitButton1,
      saveChanges1,
      shortUrl,
      twitterUrl,
      berita,
      MediaSosialForm,
      MediaSosialFormValidator,
      loading,
      remoteMethod,
      media_options,
      convertText,
      valueContent,
      keyContent,
      isNumber,
      rateAccountMedia,
      lebarAccountMedia,
      getContentUrl,
      isEnableField,
      getDataButton1,
    };
  },
  methods: {
    setEmotion(value) {
      if (value == this.MediaSosialForm.emotion) {
        this.MediaSosialForm.emotion = "";
      } else {
        this.MediaSosialForm.emotion = value;
      }
    },
    setSentiment(value) {
      if (value == this.MediaSosialForm.sentiment) {
        this.MediaSosialForm.sentiment = "";
      } else {
        this.MediaSosialForm.sentiment = value;
      }
    },
  },
});
